@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}

body {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background: #0fb8ac;
}

.App {
  font-family: Arial, sans-serif;
  text-align: center;
  background-color: #fbfcf8;
  padding: 2rem;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: linear-gradient(to right, #2c3e50, #4ca1af);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

button[type="submit"] {
  height: 51px;
  min-width: 200px;
  margin-top: 16px;
  border-radius: 8px;
  background: #51bf81;
  color: #fff;
  font-weight: 600;
  border: none;
  font-size: 16px;
  text-transform: uppercase;

  margin: 24px;
  margin-bottom: 0;
  cursor: pointer;
}
button[type="submit"]:active {
  transform: scale(0.98);
}

button[type="submit"]:disabled {
  transform: scale(0.98);
  pointer-events: none;
  opacity: 0.6;
}

form {
  width: 700px;
  background: rgb(19, 58, 93);
  background: radial-gradient(
    circle,
    rgba(19, 58, 93, 1) 0%,
    rgba(18, 30, 54, 1) 98%
  );
  border-radius: 20px;
  padding: 32px;

  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  position: relative;
}

.drag-area {
  border: 1px dashed #aaa;

  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  height: 300px;
  position: relative;
}
.drag-area.active {
  border: 2px solid #fff;
  background: rgb(17, 81, 137);
}

.drag-area.drag-error {
  border-color: red;
  background-color: rgba(88, 40, 40, 0.5);
}
.drag-area .icon {
  font-size: 60px;
  color: #51bf81;
}
.drag-area header {
  font-size: 20px;
  font-weight: 500;
  color: #fff;
}
.drag-area span {
  font-size: 15px;
  font-weight: 500;
  color: #fff;
  margin: 10px 0 15px 0;
}
.drag-area button {
  padding: 10px 25px;
  font-size: 16px;
  font-weight: 500;
  border: none;
  outline: none;
  background: transparent;
  color: #51bf81;
  border: 1px solid #51bf81;
  border-radius: 12px;
  cursor: pointer;
}
.drag-area img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 5px;
}

.toggle-border {
  width: 300px;
  height: 80px;
  border: 2px solid #f0ebeb;
  border-radius: 130px;
  margin-bottom: 45px;
  padding: 25px;
  background: linear-gradient(to bottom right, white, rgba(220, 220, 220, 0.5)),
    white;
  box-shadow: 0 0 0 2px #fbfbfb;
  cursor: pointer;
}

.toggle-border:last-child {
  margin-bottom: 0;
}

input[type="checkbox"] {
  display: none;
}

.label {
  position: relative;
  display: inline-block;
  width: 100px;
  height: 30px;
  background: #51bf81;
  border-radius: 80px;
  cursor: pointer;
  box-shadow: inset 0 0 16px rgba(0, 0, 0, 0.3);
  transition: background 0.5s;
}

.label:after,
.label:before {
  content: "";
  position: absolute;
  top: 2px;
  left: -50px;
  width: 20px;
  height: 20px;
  border: 2px solid #f0ebeb;
  border-radius: 50%;
  box-shadow: 0 0 0 1px #fbfbfb, inset 1px 3px 6px rgba(0, 0, 0, 0.3);
  background: #51bf81;
  transition: background 0.5s;
}

.label:before {
  left: calc(100% + 50px - 20px);
  background: #d1cac9;
}

input[type="checkbox"]:checked + label {
  background: rgb(17, 81, 137);
}

.handle {
  position: absolute;
  top: -6px;
  left: -5px;
  width: 40px;
  height: 40px;
  border: 1px solid #e5e5e5;
  background: repeating-radial-gradient(
      circle at 50% 50%,
      rgba(200, 200, 200, 0.2) 0%,
      rgba(200, 200, 200, 0.2) 2%,
      transparent 2%,
      transparent 3%,
      rgba(200, 200, 200, 0.2) 3%,
      transparent 3%
    ),
    conic-gradient(
      white 0%,
      silver 10%,
      white 35%,
      silver 45%,
      white 60%,
      silver 70%,
      white 80%,
      silver 95%,
      white 100%
    );
  border-radius: 50%;
  box-shadow: 3px 5px 10px 0 rgba(0, 0, 0, 0.4);
  transition: left 0.4s;
}

input[type="checkbox"]:checked + label > .handle {
  left: calc(100% - 40px + 5px);
}

input[type="checkbox"]:checked + label:after {
  background: #d1cac9;
}

input[type="checkbox"]:checked + label:before {
  background: rgb(17, 81, 137);
}
.toggle {
  display: flex;
  align-items: start;

  margin-bottom: 8px;

  span {
    margin-top: 2px;
    font-weight: 600;
  }

  :first-child {
    color: #51bf81;
  }

  :last-child {
    color: rgb(4, 39, 70);
  }
}

.toggle-input {
  width: 240px;
}

h1 {
  position: absolute;
  top: 96px;
  color: #fafafa;

  font-size: 46px;

  font-weight: 500;
  span {
    color: #51bf81;
  }
}

.selected-file,
.error {
  position: absolute;
  bottom: 12px;
  color: #fafafa;

  display: flex;
  gap: 6px;
  align-items: center;
}

.error {
  color: rgb(196, 7, 7);
}

.result-file,
.result-error {
  color: #fafafa;
  opacity: 0;
  font-size: large;

  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: -50px;

  margin-top: 12px;

  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
  animation: smooth-appear 1s ease forwards;

  a {
    color: #51bf81;
    animation: bounce 1s both ease-out 1;

    i {
      font-size: 24px;
      transition: transform 0.2s;

      &:hover {
        transform: scale(1.25);
      }
    }
  }
}

.result-error {
  color: red;
  font-size: large;
  bottom: 0;
  transform: translate(-50%, calc(100% + 10px));

  border-radius: 10px;
  background-color: rgba(140, 16, 16, 0.5);
  padding: 12px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  width: 100%;
}

@keyframes smooth-appear {
  to {
    opacity: 1;
  }
}

.loader {
  animation: spin 1.5s infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes bounce {
  0% {
    transform: translateY(0);
  }
  25% {
    transform: translateY(6px);
  }
  50% {
    transform: translateY(0);
  }
  75% {
    transform: translateY(6px);
  }
  100% {
    transform: translateY(0);
  }
}
